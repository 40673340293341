.light{
  --nowGrey: #585858;
}
.dark{
  --nowGrey: #bababa;
}

.forecast.forecast2 {
  padding: 8px 8px 5px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  > .header {
    flex: 0 1 56px;
    box-sizing: border-box;
    padding: 0 18px;
    font-family: Montserrat, serif;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    display: flex;

    > div:first-of-type {
      flex: 1 1;
      font-family: Ubuntu, serif;
      font-size: 30px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--dark-gray);
    }

    > div:nth-of-type(2) {
      font-weight: 800;
      color: var(--dark-gray);
    }

    > div:last-of-type {
      flex: 1 1;
      text-align: right;

      > span:first-of-type {
        margin-right: 36px;
      }
    }
  }
  .no-forecast{
    text-align: center;
    padding: 30px 0;
  }

  > .hourlies {
    flex-basis: 378px;
    background-image: linear-gradient(to right, rgba(249, 249, 249, 0.61) 0%, #f3fbff 124%);
    display: flex;

    > .now {
      flex-basis: 268px;
      padding-left: 16px;
      padding-top: 18px;

      > .title {
        font-family: Bahnschrift, serif;
        font-size: 70px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--black);
      }

      > .temp-icon {
        font-family: Bahnschrift, serif;
        font-size: 100px;
        font-weight: 600;
        font-stretch: 90%;
        color: var(--black);
        display: flex;
        align-items: center;

        > .weather-icon {
          height: 70px;
          flex-grow: 1;
        }
      }

      > .rain-prob {
        font-family: Bahnschrift, serif;
        font-size: 40px;
        font-weight: 300;
        color: var(--black);

        > * {
          vertical-align: middle;
        }

        > svg {
          transform: scale(1.5);
          margin-right: 10px;
          margin-left: 10px;
        }
      }

      > .hl-temp {
        font-family: Bahnschrift, serif;
        font-size: 44px;
        font-weight: 600;
        font-stretch: 90%;
        color: var(--nowGrey);
        margin-top: 42px;
      }
    }

    > .the-rest {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      > .row {
        display: flex;
        width: 100%;

        > div {
          flex-grow: 1;
          text-align: center;

          &.title {
            font-family: Bahnschrift, serif;
            font-size: 40px;
            font-weight: 300;
            color: var(--black);
            margin-top: 28px;
          }

          &.icon {
            margin-top: 28px;

            > .weather-icon {
              width: 69px;
              height: 47px;
              margin-left: auto;
              margin-right: auto;
            }
          }

          &.rain-prob {
            margin-top: 28px;
            font-family: Bahnschrift, serif;
            font-size: 40px;
            font-weight: 300;
            color: var(--black);

            > * {
              vertical-align: middle;
            }
          }
        }
        &.graph{
          flex-grow: 1;

          text{
            font-family: Bahnschrift, serif;
            font-size: 60px;
            font-weight: 600;
            font-stretch: 90%;
            fill: var(--black);
          }
        }
      }
    }
  }

  > .dailies {
    flex-grow: 1;
    display: flex;

    > div {
      flex-grow: 1;
      background-image: linear-gradient(to right, rgba(249, 249, 249, 0) 0%, #f3fbff 100%);
      display: flex;
      flex-direction: column;
      padding-top: 12px;
      padding-left: 16px;

      > div {
        flex-grow: 1;

        &:first-of-type {
          display: flex;

          > div {
            flex-grow: 1;

            .weather-icon {
              height: 92px;
              width: 140px;
            }

            .week-day {
              font-family: Bahnschrift, serif;
              font-size: 40px;
              font-weight: 600;
              color: var(--black);
            }

            .month-day {
              font-family: Bahnschrift, serif;
              font-size: 24px;
              font-weight: 300;
              color: var(--black);
            }

            .rain-prob {
              font-family: Bahnschrift, serif;
              font-size: 36px;
              font-weight: 600;
              letter-spacing: 0.01px;
              color: var(--black);

              > * {
                vertical-align: middle;
              }

              > svg {
                transform: scale(1.5);
              }
            }
          }
        }
      }
      .min-max {
        font-family: Bahnschrift, serif;
        font-size: 60px;
        font-weight: 600;
        font-stretch: 90%;
        color: var(--black);
        padding-top: 30px;
      }
    }
  }
}

.forecast.forecast2 {
  .dark {
    display: none;
  }
}

.dark .forecast.forecast2 {
  .light {
    display: none;
  }

  .dark {
    display: inline-block;
  }

  > .hourlies {
    background-image: linear-gradient(to right, rgba(152, 152, 152, 0.18) 1%, rgba(0, 0, 0, 0.26) 100%);
  }
  > .dailies {
    > div {
      background-image: linear-gradient(to right, rgba(152, 152, 152, 0.18) -126%, rgba(0, 0, 0, 0.26) 100%);
    }
  }
}