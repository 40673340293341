.small-card .metric-card-body {
  .leak-wrapper .leak-item {
    .label {
      font-size: 60px;
      line-height: 57px;
    }

    .leak-value {
      font-size: 21px;
    }
  }
}

.medium-card .metric-card-body {
  .leak-wrapper .leak-item {
    .label {
      font-size: 66px;
      line-height: 57px;
    }

    .leak-value {
      font-size: 30px;
    }
  }
}

.large-card .metric-card-body {
  .leak-wrapper .leak-item {
    padding-bottom: 1.5em;

    .label {
      font-size: 66px;
      line-height: 57px;
    }

    .leak-value {
      font-size: 30px;
    }
  }
}


.leak-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding: 0 1em;
}

.leak-item {
  flex: 1 0 50%;
  display: flex;
  align-items: baseline;
  flex-direction: row;

  .label {
    font-family: Bahnschrift, serif;
    font-weight: bold;
    font-stretch: 85%;
    letter-spacing: -0.83px;
  }

  .leak-value {
    font-family: Montserrat, serif;
    font-weight: bold;
    font-stretch: normal;
    margin-left: 0.5em;
  }
}