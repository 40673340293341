
.list-body{
  display: flex;
  justify-content: space-around;

  .label{
    font-family: Montserrat,serif;
  }
}

.large-card .metric-card-body.etos{
  --body-padding-top: 49px;
  --body-padding-bottom: 30px;

  .list-body{
    .label{
      font-size: 1.5em;
      margin-bottom: 0.5em;
    }
  }
}

.large-card.etos .metric-card-footer{
  --footer-name-font-size: 50px;
  >.footer-column > div:first-of-type {
    font-weight: 600;
  }
}

.medium-card .metric-card-body.etos{
  --body-padding-top: 19px;    
  --body-padding-bottom: 17px;

  .list-body{
    .label{
      font-size: 1.5em;
      margin-bottom: 0.5em;
    }
  }
}

.medium-card.etos .metric-card-footer{
  --footer-name-font-size: 36px;
  >.footer-column > div:first-of-type {
    font-weight: 600;
  }
}

.small-card .metric-card-body.etos{
  --body-padding-top: 19px;

  .list-body{
    .label{
      font-size: 1.12em;
      margin-bottom: 0.2em;
    }
  }
}

.small-card.etos .metric-card-footer{
  --footer-name-font-size: 24px;
   >.footer-column > div:first-of-type {
    font-weight: 600;
  }
}

.etos{
  .metric-card-body{
    margin-left: -5px;
    margin-right: -5px;
  }
}
