.small-card.uv{
  --body-padding-top: 25px;
  --description-margin-top: 10px;
  --uv-image-margin-top: 11px;
  --uv-background-image: url(../../assets/uv-range-small.png) no-repeat;
  --description-font-size: 18px;
  --uv-image-width: 276px;
  --uv-image-height: 34px;
}
.medium-card.uv{
  --body-padding-top: 54px;
  --description-margin-top: 29px;
  --uv-image-margin-top: 11px;
  --uv-background-image: url(../../assets/uv-range-medium.png) no-repeat;
  --description-font-size: 24px;
  --uv-image-width: 333px;
  --uv-image-height: 41px;
}
.large-card.uv{
  --body-padding-top: 88px;
  --description-margin-top: 24px;
  --uv-image-margin-top: 20px;
  --uv-background-image: url(../../assets/uv-range-large.png) no-repeat;
  --description-font-size: 28px;
  --uv-image-width: 455px;
  --uv-image-height: 56px;
}

.uv{
  .uv-card-body{
    text-align: center;
    padding-top: var(--body-padding-top);

    .description{
      font-family: Montserrat, serif;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      color: var(--dark-gray);
      margin-top: var(--description-margin-top);
      font-size: var(--description-font-size);
    }
    .uv-range{
      display: inline-block;
      background: var(--uv-background-image);
      background-size: auto;
      width: var(--uv-image-width);
      height: var(--uv-image-height);
      margin-top: var(--uv-image-margin-top);
    }
  }
}