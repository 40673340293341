
.gauge{
    $h: 15px;
    height: $h;
    background: url(./../../ambient/assets/co2-gauge.svg) center center no-repeat;
    background-size: 100% auto;
    width: 90%;
    border-radius: $h / 2;
    position: relative;
    margin: 2em auto 0;
    align-self: center;
    .dot{
      $dotH: $h * 1.4;
      height: $dotH;
      width: $dotH;
      position: absolute;
      top: -(($dotH - $h) / 2);
      left: -(($dotH - $h) / 2);
      z-index: 2;

    &.white {
      background: url(./../../ambient/assets/co2-dot-white.svg) center center no-repeat;
      background-size: 100%;
    }

    &.black {
      background: url(./../../ambient/assets/co2-dot-black.svg) center center no-repeat;
      background-size: 100%;
    }
  }
}

  .medium-card.co2 .metric-card-body{
    --body-padding-bottom: 12px;

    .gauge{
      margin: 0.5em auto 0;
    }
  }

  .small-card.co2, .small-card.co2_in {
    .metric-card-body {
      padding-top: 23px;

      .gauge {
        margin: 1em auto 0;
      }
    }

    .metric-card-footer {
      > .footer-column:nth-of-type(2) {
        padding: 0 15px;
        max-width: 103px;
        overflow-wrap: break-word;
      }
    }
  }

  .small-card.co2 .gauge.sensitive{
    margin: 0.5em auto 0;
  }
  .medium-card.co2 .metric-card-footer{
    >.footer-column:nth-of-type(2){
      padding: 0 15px;
    }
  }

  .large-card.co2 .metric-card-footer{
    >.footer-column:nth-of-type(2){
      padding: 0 13px;
    }
  }

  .large-card.co2 .metric-card-body{
    --body-padding-bottom: 31px;
  }

  .large-card.co2_in .metric-card-body{
    --body-padding-bottom: 31px;
  }
