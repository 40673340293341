.time{
  >.metric-card-body{
    padding-bottom: 0;
  }
  &.small-card{
    >.metric-card-body{
      padding-top: 17px;
      .central-value{
        font-family: Bahnschrift, serif;
        font-size: 88px;
        font-weight: bold;
        font-stretch: 85%;
        font-style: normal;
        letter-spacing: -1.1px;
        color: var(--dark-gray);

        >span{
          font-family: Bahnschrift, serif;
          font-size: 24px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-transform: uppercase;
        }
      }
      .week-date{
        font-family: Montserrat, serif;
        font-size: 21px;
        font-weight: bold;
        color: var(--black);
        margin-top: 3px;
      }
    }
  }
  &.medium-card{
    >.metric-card-body{
      padding-top: 26px;
      .central-value{
        font-family: Bahnschrift, serif;
        font-size: 110px;
        font-weight: bold;
        font-stretch: 85%;
        letter-spacing: -1.38px;
        color: var(--dark-gray);

        >span{
          font-family: Bahnschrift, serif;
          font-size: 36px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-transform: uppercase;
        }
      }
      .week-date{
        font-family: Montserrat, serif;
        font-size: 24px;
        font-weight: bold;
        color: var(--black);
        margin-top: 8px;
      }
    }
  }
  &.large-card{
    >.metric-card-body{
      padding-top: 58px;
      .central-value{
        font-family: Bahnschrift, serif;
        font-size: 130px;
        font-weight: bold;
        font-stretch: 85%;
        letter-spacing: -1.63px;
        color: var(--dark-gray);

        >span{
          font-family: Bahnschrift, serif;
          font-size: 50px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-transform: uppercase;
        }
      }
      .week-date{
        font-family: Montserrat, serif;
        font-size: 28px;
        font-weight: bold;
        color: var(--black);
        margin-top: 18px;
      }
    }
  }
}