$blue: #0aa8e6;

.device-high-low-graphs {
    .line-wrap{
      padding: 0 0 30px;
      display: flex;
      .label{
        font-size: 0.7em;
        flex: 32%;
        padding-right: 10px;
        text-align: left;
        min-width: 60px;
        width: 60px;
      }
      .line-bg{
        $h: 7px;
        height: $h;
        border-radius: 3px;
        background-color: #dbdbdb;
        align-self: center;
        flex: 77%;
        position: relative;
        .line{
          $p: 10px;
          position: absolute;
          left: 0;
          top: 0;
          height: $h;
          background-color: $blue;
          width: 20px;
          display: flex;
          justify-content: space-between;
          .min, .max{
            color: var(--black);
            font-family: Bahnschrift, serif;
            font-size: 14px;
            position: absolute;
            padding-top: $p;
            left: -15px;
            width: 0;

            &:after{
              content: " ";
              width: 14px;
              height: 14px;
              border-radius: 100%;
              background-color: #fff;
              border: 1px solid $blue;
              position: absolute;
              top: -$p + 4px;
              left: 6px;
            }
           
          }

          .min{
            .extreme-val{
              position: absolute;
              right: -17px;
            }
          }
          .max{
            left: auto;
            right: 0;
            &:after{
              left: auto;
              right: -11px;
            }

            .extreme-val{
              position: absolute;
              left: 0;
            }
          }
          .avg{
            position: absolute;
            top: 0;
            color: #888585;
            font-family: Bahnschrift, serif;
            font-size: 12px;        
            padding-top: 11px;
            &:after{
              content: " ";
              // .square(9px);
              height: 11px;
              width: 11px;
              border-radius: 100%;
              background-color:  #ffd81d;
              border: 1px solid #fff;
              position: absolute;
              top: -4px;
              left: 5px;
            }

            .avg-val{
              position: absolute;
              left: 2px;
            }
          }
        }
      }
    }
}