.metric-card{
  border-radius: 10px;
  border: solid 1px var(--border-color);
  background-color: var(--background-color);
  padding: 10px 13px;
  display: inline-flex;
  color: var(--black);
  box-sizing: border-box;
  overflow: hidden;
  flex-flow: column;

  &.small-card{
    height: 261px;
    width: 310px;
  }
  &.medium-card{
    width: 416px;
    height: 341px;
    padding: 15px 21px;


    .subtitle{
      margin-top: -3px;
    }
  }
  &.large-card{
    width: 628px;
    height: 422px;
    padding: 10px 14px;
  }
}