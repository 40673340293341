.light {
  .large-card.pressure{
    --background-image: url("../../assets/pressure-arc-light.svg");
  }
  .pressure-footer {
    --background-image: linear-gradient(to left, #3c3c3c, #dfdfdf);
  }
}
.dark{
  .large-card.pressure{
    --background-image: url("../../assets/pressure-arc-dark.svg");
  }
  .pressure-footer {
    --background-image: linear-gradient(to left, #dfdfdf, #3c3c3c);
  }
}

.small-card .pressure-footer{
  --value-margin-top: 10px;
  --value-font-size: 18px;
  --icon-width: 22px;
  --icon-height: 22px;
  --icon-margin-right: 4px;
  --icon-margin-top: 0;
  --display-bar: block;
}
.medium-card .pressure-footer{
  --value-margin-top: 14px;
  --value-font-size: 24px;
  --icon-width: 25px;
  --icon-height: 22px;
  --icon-margin-right: 6px;
  --icon-margin-top: 3px;
  --display-bar: block;
}
.large-card .pressure-footer{
  --value-margin-top: 2px;
  --value-font-size: 28px;
  --icon-width: 25px;
  --icon-height: 25px;
  --icon-margin-right: 7px;
  --icon-margin-top: 4px;
  --display-bar: none;
}

.small-card.pressure{
  --background-image: unset;
  --big-value-display: inline;
  --counter-suffix-display: inline;
  --arrow-display: none;
}
.medium-card.pressure{
  --background-image: unset;
  --big-value-display: inline;
  --counter-suffix-display: inline;
  --arrow-display: none;
}
.large-card.pressure{
  --big-value-display: block;
  --counter-suffix-display: none;
  --arrow-display: block;
}
.large-card.pressure .metric-card-body{
  --body-padding-bottom: 87px;
  --body-padding-top: 116px;
}
.pressure{
  >.metric-card-body{
    background-image: var(--background-image);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: var(--body-padding-bottom);
    padding-top: var(--body-padding-top);
    position: relative;

    >svg{
      position: absolute;
      display: var(--arrow-display);

      >path{
        fill: var(--black);
      }
    }
    >.big-value{
      .counter-suffix{
        display: var(--counter-suffix-display);
      }
      .fdp-val{
        display: var(--big-value-display);
      }
      .suff{
        display: var(--big-value-display);
      }
    }
  }
}
.pressure-footer{
  text-align: center;

  >div:first-of-type{
    margin-top: -5px;
    display: var(--display-bar);
    >div:first-of-type{
      position: relative;
      width: 238px;
      height: 7px;
      border-radius: 26px;
      background-image: var(--background-image);
      background-color: unset;
      display: inline-block;

      >div{
        width: 16px;
        height: 16px;
        background-color: var(--dark-gray);
        display: inline-block;
        border-radius: 8px;
        position: absolute;
        top: -4px;
      }
    }
  }
  >div:last-of-type{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: Montserrat, serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    margin-top: var(--value-margin-top);
    font-size: var(--value-font-size);
    >svg{
      width: var(--icon-width);
      height: var(--icon-height);
      margin-right: var(--icon-margin-right);
      margin-top: var(--icon-margin-top);
    }
  }
}