.light {
  .large-card.pm25, .large-card.pm25_in {
    --background-image: url("../../assets/pm25-arc.svg");
    --background-repeat: no-repeat;
    --background-size: cover;
    --background-attachment: fixed;
  }
}

.dark {
  .large-card.pm25, .large-card.pm25_in {
    --background-image: url("../../assets/pm25-arc-white.svg");
    --background-repeat: no-repeat;
    --background-size: cover;
    --background-attachment: fixed;
  }
}

.small-card.pm25, .large-card.pm25_in {
  --background-image: unset;
  --big-value-display: inline;
  --counter-suffix-display: inline;
  --arrow-display: none;
}

.medium-card.pm25, .large-card.pm25_in {
  --background-image: unset;
  --big-value-display: inline;
  --counter-suffix-display: inline;
  --arrow-display: none;
}

.large-card.pm25, .large-card.pm25_in {
  --big-value-display: block;
  --counter-suffix-display: none;
  --arrow-display: block;

  .big-value {
    .suff {
      bottom: 0.3em;
    }

    .big-number {
      position: relative;
      bottom: 0.4em;
    }
  }
}

.large-card.pm25, .large-card.pm25_in {
  .metric-card-body {
    --body-padding-bottom: 87px;
    --body-padding-top: 116px;
  }
}

.large-card.pm25, .large-card.pm25_in {
  .metric-card-footer {
    margin-top: -2em;
  }
}

.small-card.co2 .metric-card-footer {
  > .footer-column:nth-of-type(2) {
    padding: 0 15px;
    overflow-wrap: break-word;
  }
}

.pm25, .pm25_in {
  > .metric-card-body {
    background-image: var(--background-image);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: var(--body-padding-bottom);
    padding-top: var(--body-padding-top);
    position: relative;

    > svg {
      position: absolute;
    }

    > .big-value {
      .counter-suffix {
        display: var(--counter-suffix-display);
      }

      .fdp-val {
        display: var(--big-value-display);
      }

      .suff {
        display: var(--big-value-display);
      }
    }
  }
}

.dark {
  .large-card.pm25, .large-card.pm25_in {
    .metric-card-body {
      svg {

        path:first-of-type {
          fill: var(--black);
        }
      }
    }
  }
}
