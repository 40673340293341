.metric-card.daily-forecast {
  display: inline-flex;
  flex-direction: column;
  height: 207px;
  width: 628px;

  .daily-forecast-widget {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    .outer-wrap {
      display: flex;
      flex-grow: 1;
      justify-content: space-around;
      text-align: center;
      padding: 10px 0 0;

      .dailies {
        flex-grow: 1;
        display: flex;

        .month-day {
          display: none;
        }

        > div:not(:last-child) {
          border-right: 1px solid var(--border-color);
          margin-right: 15px;
        }
        > div {
          flex-grow: 1;
          max-width: 156px;
          display: flex;
          flex-direction: column;

          > div {
            flex-grow: 1;

            &:first-of-type {
              display: flex;

              > div {
                flex-grow: 1;

                .weather-icon {
                  width: 70px;
                  height: 48px;
                }

                .week-day {
                  font-family: Bahnschrift, serif;
                  font-size: 28px;
                  font-weight: 600;
                  color: var(--black);
                  text-align: left;
                }

                .month-day {
                  display: none;
                }

                .rain-prob {
                  font-family: Bahnschrift, serif;
                  font-size: 24px;
                  font-weight: 600;
                  letter-spacing: 0.01px;
                  color: var(--black);
                  text-align: left;
                  padding-top: 3px;

                  > * {
                    vertical-align: middle;
                  }

                  > svg {
                    margin-top: -5px;
                    transform: scale(1.1);
                  }
                }
              }
            }
          }
          .min-max {
            font-family: Bahnschrift, serif;
            font-size: 40px;
            font-weight: 600;
            font-stretch: 90%;
            color: var(--black);
            text-align: left;

            .min {
              font-size: 30px;
            }
          }
        }
      }

      .no-data{
        font-size: 21px;
      }
    }
  }
}
