.small-card .metric-card-body{
  --body-padding-top: 46px;
  --body-padding-bottom: 12px;
}
.medium-card .metric-card-body{
  --body-padding-top: 62px;
  --body-padding-bottom: 36px;
}
.large-card .metric-card-body{
  --body-padding-top: 92px;
  --body-padding-bottom: 77px;
}
.metric-card-body{
  text-align: center;
  padding-top: var(--body-padding-top);
  padding-bottom: var(--body-padding-bottom);
  flex-grow: 1;
}