
.metric-card.sun-moon.device-widget{
  position: relative;
  display: flex;
  flex-direction: column;

}


.large-card.sun-moon{
  .device-sun-moon-widget{
    .wrap{
      .info{
        .rise, .set{
          .label{
            font-family: Montserrat, serif;
            font-size: 35px;
          }

          .sun , .moon{
            font-family: Montserrat, serif;
            font-size: 35px;
            font-weight: bold;
          }
        }

        .set{
          .label{
            text-align: left;
          }
          .sun{
            background-position: right 0 center;
            background-size: 26px;
          }
        }
        .rise{
          
          .label{
            margin-left: 32px;
          }

          .sun{
            background-size: 26px;
            padding-left: 32px;
          }
        }
      }
      .recharts-responsive-container{
        height: 357px !important;
        background-size: 90% 78%;    
        top: -48px;

        .recharts-wrapper{
          text-align: center;
          position: relative;
          cursor: default;
          width: 578px;
          height: 316px;
          
          >svg{
            width: 442px;
            height: 357px;
          }
        }
      }
    }
  }
}

.medium-card.sun-moon{
  .device-sun-moon-widget{
    .wrap{
      .info{
        .rise, .set{
          .label{
            font-family: Montserrat, serif;
            font-size: 24px;
          }

          .sun , .moon{
            font-family: Montserrat,serif;
            font-size: 24px;
            font-weight: bold;
          }
        }

        .set{
          .label{
            text-align: left;
          }
          .sun{
            background-position: right 0 center;
            background-size: 22px;
          }
        }
        .rise{
          
          .label{
            margin-left: 24px;
          }

          .sun{
            background-size: 19px;
            padding-left: 24px;
          }
        }
      }
      .recharts-responsive-container{
        background-size: 90% 100%;
        top: -2px;
        height: 219px !important;

        .recharts-wrapper{
          text-align: center;
          position: relative;
          cursor: default;
          width: 352px;
          height: 219px;
          
          >svg{
            width: 320px;
            height: 216px;
          }
        }

      }
    }
  }
}

.small-card.sun-moon .metric-card-body{
  --body-padding-top: 18px;
}

.small-card.sun-moon{
  .metric-card-body{
    .info{
      display: flex;
      justify-content: space-between;

      .rise, .set{
        .label{
          text-align: left;
          font-family: Montserrat, serif;
          font-size: 35px;
        }

        .sun , .moon{
          font-family: Montserrat, serif;
          font-size: 35px;
          font-weight: bold;
        }

        .moon{
          >svg{
            display: none;
          }
        }
      }
    }
  }
}

.device-sun-moon-widget {
  flex-grow: 1;
  display: flex;
  .recharts-pie-label-line{
    display: none;
  }
  &.error{
    flex-grow: 1;
    font-size: 0.8em;
    text-align: center;
  }
  .wrap{
    $wrapMargin: 10px;
    $wrapBottomMargin: 20px;
    position: relative;
    flex-grow: 1;
    margin: $wrapMargin $wrapMargin $wrapBottomMargin $wrapMargin;
    .recharts-responsive-container{
      position: absolute;
      top: 20px;
      left: 0;
      width: 100%;
      z-index: 10;
      background: url(../../ambient/assets/sun-moon/clock-bkg-small-ampm-light.svg) center center no-repeat;
      background-size: 100% 100%;
    }

    .info{
      position: absolute;
      bottom: -30px;
      left: -20px;
      width: calc(100% + (2 * $wrapMargin));
      z-index: 9;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      line-height: 1.2;
      padding: 0 10px 10px;
      .label{
        font-size: 16px;
      }
      .set{
        .label{
          text-align: right;
        }
        .sun{
          padding-left: 0;
          background: none;
        }
        .moon{
          svg{
            margin-right: 0;
            margin-left: 8px;
          }
        }
      }
      
      .sun{
        padding-left: 15px;
        background: url(../../ambient/assets/sun-moon/sun-circle-small.svg) 1px center no-repeat;
        background-size: 8px auto;
      }
      .moon{
        svg{
          margin-right: 6px;
        }
      }
      table{
        width: 100%;
        text-align: center;
        tr.headings{
          td{
            font-weight: 300;
          }
        }
        td{
          padding-bottom: 2px;
        }
        .sun{
          width: 10px;
          height: 12px;
          display: inline-block;
          background-size: 10px auto;
          position: relative;
          left: 2px;
        }
        .hyphen{
          display: inline-block;
          background: url(../../ambient/assets/sun-moon/grey-dash.svg) center center no-repeat;
          background-size: 10px auto;
          width: 10px;
          height: 10px;
        }
        span{
          &.arrow{
            display: inline-block;
            padding-left: 12px;
            background: url(../../ambient/assets/up-arrow-bolder.svg) left center no-repeat;
            background-size: 9px auto;
            &.neg{
              background-image: url(../../ambient/assets/down-arrow-bolder.svg);
            }
          }
        }

      }
    }
    .center{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      > div{
        font-size: 14px;
        line-height: 1.3;
        &.label{
          font-size: 14px;
          padding-bottom: 3px;
        }
      }
      &.sun{
        > div{
          font-size: 14px;
          &.label{
            font-size: 14px;
          }
        }
      }
    }
  }
}

.light {
  .sun-moon {
    .device-sun-moon-widget {
      .wrap {
        &.hour24 {
          .recharts-responsive-container {
            background-image: url(../../ambient/assets/sun-moon/clock-bkg-small-24hr-light.svg);
          }
        }
      }
    }
  }
}

.dark {
  .sun-moon {
    .device-sun-moon-widget {
      .wrap {
        .recharts-responsive-container {
          background-image: url(../../ambient/assets/sun-moon/clock-bkg-small-ampm-dark.svg);
        }

        &.hour24 {
          .recharts-responsive-container {
            background-image: url(../../ambient/assets/sun-moon/clock-bkg-small-24hr-dark.svg);
          }
        }
      }
    }
  }

}

.device-widget.sunMoon{
  display: flex;
  flex-direction: column;
  .device-sun-moon-widget.exp {
    .wrap{
      .center{
        > div{
          font-size: 16px;
          &.label{
            padding-top: 5px;
            font-size: 15px;
            padding-bottom: 0;
          }
        }
        .title{
          font-weight: 500;
        }
      }
      .info{
        bottom: -10px;
      }
    }
  }
}

