.forecast.forecast1{
  padding: 8px 8px 5px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  >.header{
    flex: 0 1 56px;
    box-sizing: border-box;
    padding: 0 18px;
    font-family: Montserrat, serif;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    display: flex;

    > div:first-of-type{
      flex: 1 1;
      font-family: Ubuntu, serif;
      font-size: 30px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--dark-gray);
    }
    > div:nth-of-type(2){
      font-weight: 800;
      color: var(--dark-gray);
    }
    > div:last-of-type{
      flex: 1 1;
      text-align: right;

      > span:first-of-type{
        margin-right: 36px;
      }
    }
  }
  >.weather-table{
    flex: 1 1 0;
    background-color: var(--table-background);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    border: var(--table-border);
    &.no-forecast{
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
    }

    >.row:last-child{
      flex: 1 1;
    }
    >.row{
      display: flex;
      flex: 0 1;

      >.row-description{
        flex: 0 1 46px;
        text-align: center;

        >.big{
          margin-top: 24px;
        }
        >.small{
          margin-top: 9px;
        }
      }
      >.cell{
        flex: 1 1 0;
        background-image: var(--column-background);

        >.weather-icon{
          background-position-x: center;
          width: 100%;
          margin-bottom: 7px;
        }

        &.title{
          font-family: Bahnschrift, serif;
          font-size: 40px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: var(--black);
          padding-top: 18px;
          padding-bottom: 7px;
        }
        &.big{
          font-family: Bahnschrift, serif;
          font-size: 60px;
          font-weight: 600;
          font-stretch: 90%;
          font-style: normal;
          font-variation-settings: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: var(--black);
        }
        &.small{
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Bahnschrift, serif;
          font-size: 30px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -2.57px;
          text-align: center;
          color: var(--black);
          >span:first-of-type{
            margin-right: 2px;
          }
        }
        &.rain-prob{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          > *{
            vertical-align: middle;
          }
          > svg{
            margin-left: 2.4em;
          }
          .small-row{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            >span:first-of-type{
              margin-right: 2px;
            }
          
          }
        }
        &.wind{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          > *{
            vertical-align: middle;
          }
          > svg{
            margin-left: 1.5em;
          }
          >span{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      &.bottom-border{
        border-bottom: solid 1px #fff;
      }
    }
  }
}
.forecast.forecast1{
  --column-background: linear-gradient(92deg, #f5f5f6 4%, rgba(232, 232, 233, 0) 95%);
  --table-border: solid 1px #999;
  --table-background: rgba(237, 238, 240, 0.5);

  .dark{
    display: none;
  }

  .hourly{
    .row:nth-child(4), .row:nth-child(5), .row:nth-child(6){
      min-height: 40px;
    }
  }
}
.dark .forecast.forecast1{
  --column-background: linear-gradient(to right, rgba(245, 245, 246, 0.15) 0%, rgba(232, 232, 233, 0) 100%);
  --table-border: solid 1px rgba(255, 255, 255, 0.6);
  --table-background: unset;

  .light{
    display: none;
  }
  .dark{
    display: inline-block;
  }
}