.wind-rose{
  text{
    font-family: Ubuntu, serif;
    fill: var(--black);
    stroke: var(--black);
  }
  circle.outer-circle{
    stroke: var(--black);
  }
  >.metric-card-body{
    min-height: 0;
  }
  >.metric-card-footer{
    display: block;
    >div{
      display: inline-block;
    }
  }

  &.large-card{
    >.metric-card-body{
      padding-top: 20px;
      padding-bottom: 0;
    }
    >.metric-card-footer{
      margin-bottom: 14px;

      >div:last-of-type,
      >div:nth-of-type(2){
        float: right;
      }
      >div:first-of-type{
        margin-left: 15px;
      }
      >div:nth-of-type(2){
        margin-right: 20px;
      }
      >div:last-of-type{
        margin-right: 20px;
      }
    }
  }
  &.medium-card{
    >.metric-card-body{
      padding-top: 0;
      padding-bottom: 0;
    }
    >.metric-card-footer{
      margin-bottom: 8px;

      >.footer-column > div:last-of-type{
        font-size: 20px;
      }
      >div:first-of-type{
        margin-left: -7px;
      }
      >div:last-of-type,
      >div:nth-of-type(2){
        float: right;
      }
      >div:nth-of-type(2){
        margin-right: -5px;
      }
      >div:last-of-type{
        margin-right: 9px;
      }
    }
  }
  &.small-card{
    >.metric-card-body{
      padding-top: 0;
      padding-bottom: 0;
      text-align: center;

      >svg{
        margin-top: 6px;
        margin-bottom: 6px;
      }
    }
    >.metric-card-footer{
      margin-bottom: 3px;

      >.footer-column > div:last-of-type{
        font-size: 18px;
      }
      >div:first-of-type{
        margin-left: 3px;
      }
      >div:last-of-type{
        float: right;
      }
    }
  }
}