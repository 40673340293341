.widget-gallery{
  .row{
    margin-bottom: 10px;

    >div{
      margin: 10px;
      vertical-align: middle;
    }
  }

  &.dark{
    background-color: #0b2330;
    >label{
      color: #fff;
    }
  }
}