.metric-card.outdoor{
  display: flex;
  flex-direction: column;
}
.device-temp-widget {
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .device-high-low-graphs {
    .line-wrap {
      .label {
        font-family: Montserrat, serif;
        font-size: 20px;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  }
  .expanded-wrap{
    flex-grow: 1;
  }
  .outdoor-footer{
    display: flex;

    >div{
      flex-grow: 1;
      text-align: center;
      font-family: Montserrat, serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--black);

      >span:last-of-type{
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }
  }
}

.medium-card.device-widget {
  .device-temp-widget {
    .device-high-low-graphs {
      .line-wrap:last-child {
        padding-bottom: 0;
      }
      .line-wrap {
        padding: 0 0 20px;

        .label {
          font-size: 1.2em
        }

        .line-bg {
          .line {
            .min, .max {
              font-size: 20px;
            }

            .avg {
              font-size: 16px;
              font-stretch: semi-condensed;
            }
          }
        }
      }
    }
  }
}

.large-card.device-widget {
  .device-temp-widget {
    .device-high-low-graphs {
      padding: 0 1em;

      .line-wrap:last-child {
        padding-bottom: 0;
      }
      .line-wrap {
        padding: 0 0 30px;

        .label {
          font-size: 1.6em;
        }

        .line-bg {
          .line {
            .min, .max {
              font-size: 24px;
            }

            .avg {
              font-size: 20px;
              font-stretch: semi-condensed;
            }
          }
        }
      }
    }

    .outdoor-footer{
      >div{
        font-size: 24px;

        >span:last-of-type{
          font-size: 28px;
        }
      }
    }
  }
}