.small-card.humidity-card{
  --title-font-size: 18px;
  --value-margin-top: 3px;
  --value-font-size: 21px;
  --icon-width: 22px;
  --icon-height: 22px;
  --icon-margin-right: 4px;
  --icon-margin-top: 0;
}
.medium-card.humidity-card{
  --title-font-size: 21px;
  --value-margin-top: 5px;
  --value-font-size: 24px;
  --icon-width: 25px;
  --icon-height: 22px;
  --icon-margin-right: 6px;
  --icon-margin-top: 3px;
}
.large-card.humidity-card{
  --title-font-size: 24px;
  --value-margin-top: 2px;
  --value-font-size: 28px;
  --icon-width: 25px;
  --icon-height: 25px;
  --icon-margin-right: 7px;
  --icon-margin-top: 4px;
}

  .humidity-footer{
    >div:first-of-type{
      font-family: Montserrat, serif;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--black);
      text-align: center;
      font-size: var(--title-font-size);
    }
    >div:last-of-type{
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-family: Montserrat, serif;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--black);
      margin-top: var(--value-margin-top);
      font-size: var(--value-font-size);
      >svg{
        width: var(--icon-width);
        height: var(--icon-height);
        margin-right: var(--icon-margin-right);
        margin-top: var(--icon-margin-top);
      }
    }
  }

.big-value{
  .big-number{
    font-family: Bahnschrift, serif;
    font-weight: bold;
    font-stretch: 85%;
    font-style: normal;
    color: var(--dark-gray);
    font-size: var(--big-number-font-size);
    line-height: var(--big-number-line-height);
    height: var(--big-number-height);

    >span {
      position: relative;
      bottom: var(--big-number-bottom);
    }
  }
  .with-small-unit>span:before,
  .with-small-unit>span:after{
    font-size: var(--big-number-small-unit-font-size);
  }
  .suffix>span:after{
    content: attr(data-suffix);
    font-family: Bahnschrift, serif;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: var(--dark-gray);
  }
  .prefix>span:before{
    content: attr(data-prefix);
    font-family: Bahnschrift, serif;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    color: var(--dark-gray);
  }
}


.humidity-footer-data{
  display: flex;
  justify-content: center;
  align-items: center;

  .humidity-icon{
    margin-right: 0.2em;
  }
}