.light {
  .large-card.aqin {
    position: relative;
    > .metric-card-body {
      background-image: url("../../assets/pm25-arc.svg");
      background-size: 80%;
      .arrow {
        fill: black;
      }
    }
  }
}

.dark {
  .large-card.aqin {
    position: relative;
    > .metric-card-body {
      background-image: url("../../assets/pm25-arc-white.svg");
      background-size: 80%;
      .arrow {
        fill: white;
      }
    }
  }
}

.small-card.aqin {
  > .metric-card-body {
    background-image: unset;

    > .big-value {
      .counter-suffix {
        display: inline;
      }
      .fdp-val {
        display: inline;
      }
      .suff {
        display: inline;
      }
    }
  }
}

.medium-card.aqin {
  > .metric-card-body {
    background-image: unset;

    > .big-value {
      .counter-suffix {
        display: inline;
      }
      .fdp-val {
        display: inline;
      }
      .suff {
        display: inline;
      }
    }
  }
}

.large-card.aqin {
  .big-value {
    .suff {
      bottom: 0.3em;
    }

    .big-number {
      position: relative;
      bottom: 0.4em;
    }
    .counter-suffix {
      display: none;
    }
    .fdp-val {
      display: block;
    }
    .suff {
      display: block;
    }
  }
}

.large-card.aqin {
  .metric-card-body {
    padding-bottom: 87px;
    padding-top: 116px;
  }
}

.large-card.aqin {
  .metric-card-footer {
    margin-top: -2em;
  }
}

.small-card.co2 .metric-card-footer {
  > .footer-column:nth-of-type(2) {
    padding: 0 15px;
    overflow-wrap: break-word;
  }
}

.aqin {
  > .metric-card-body {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;

    > svg {
      position: absolute;
    }
  }
}

.large-card.aqin {
  .metric-card-body {
    background-position-x: center;
    background-position-y: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    padding-bottom: 77px;
    padding-top: 133px;

    .fdp-val {
      bottom: -66px;
    }
  }
}

.dark {
  .large-card.aqin {
    .metric-card-body {
      svg {
        path:first-of-type {
          fill: var(--black);;
        }
      }
    }
  }
}
