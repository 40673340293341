.listBody {
  display: flex;
  justify-content: space-around;
}

.soiltension {
  padding-bottom: 13px;
  padding-top: 92px;
}

.dark {
  .soiltension {
    svg {
      path {
        fill: var(--black);
      }
    }
  }
}

