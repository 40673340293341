.small-card .metric-card-footer{
  --footer-name-font-size: 18px;
  --footer-value-font-size: 21px;
}
.medium-card .metric-card-footer{
  --footer-name-font-size: 21px;
  --footer-value-font-size: 24px;
}
.large-card .metric-card-footer{
  --footer-name-font-size: 24px;
  --footer-value-font-size: 28px;
}
.metric-card-footer{
  font-family: Montserrat, serif;
  display: flex;
  >.footer-column{
    text-align: center;
    flex: 1 1 auto;
    > div:first-of-type{
      font-size: var(--footer-name-font-size);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--black);
    }
    > div:last-of-type{
      font-size: var(--footer-value-font-size);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--black);
    }
  }
}