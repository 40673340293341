.metric-card.forecast {
  display: inline-flex;
  flex-direction: column;
  height: 207px;
  width: 628px;

  .forecast-widget {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    .outer-wrap {
      display: flex;
      flex-grow: 1;
      justify-content: space-around;
      text-align: center;
      padding: 10px 0 0;

      .hour {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .title {
          font-family: Bahnschrift, serif;
          font-size: 1.5em;
          font-weight: bold;
          font-stretch: 85%;
          letter-spacing: -0.3px;
        }

        .weather-icon {
          height: 26px;
          width: 33px;
        }

        .fdp {
          font-family: Bahnschrift,serif;
          font-size: 44px;
          font-weight: bold;
          font-stretch: 85%;
          letter-spacing: -0.55px;
        }
      }

      .no-data{
        font-size: 21px;
      }
    }
  }
}
