.rain.device-widget {
  display: inline-flex;
  flex-direction: column;
}

.medium-card .rain-widget,
.large-card .rain-widget {
  .outer-wrap .rain-wrap {

    flex-direction: column;
    padding: 9px 5px 5px;

    .rain-tube {
      width: 50px;
      height: 185px;
      margin: auto;

      .bg {
        margin-bottom: 0;
        height: inherit;
      }
    }

    .wrap {
      padding-top: 15px;

      .formatted-value {
        font-size: 3.75em;
        letter-spacing: -0.75px;
        font-stretch: 85%;
        font-weight: bold;
        font-family: Bahnschrift, serif;

        .fdp-val {
          display: block;
          margin: 0;
          padding: 0;
          line-height: 0.84;
        }
      }

      .label {
        font-family: Bahnschrift, serif;
        font-size: 2.5em;
        font-weight: bold;
        font-stretch: 85%;
        padding-top: 4px;
      }
    }

    .fdp {
      margin-bottom: 0;
      padding-bottom: 6px;
    }
  }


  .last-rain {
    top: -46px;
    right: 0;
    font-size: 1.5em;
  }
}

.long-card .rain-widget {
  .outer-wrap .rain-wrap {
    flex-direction: row;
    padding: 16px 0 0;

    .rain-tube {
      width: 30px;
      margin-right: 0.8em;
    }

    .wrap {
      .formatted-value {
        font-size: 2.75em;
        letter-spacing: -0.55px;
      }

      .fdp {
        margin: 0;
        padding-bottom: 13px;
        line-height: 0.8;
      }

      .label {
        font-family: Bahnschrift, serif;
        font-size: 2.5em;
        font-weight: bold;
        font-stretch: 85%;
        line-height: 1.3;
      }
    }
  }

  .last-rain {
    top: -44px;
    right: 0;
    font-size: 1.1em;
  }
}
.medium-card .rain-widget{
  .outer-wrap {
    padding: 0 10px;
    .rain-wrap {
      justify-content: center;
      flex: 1;
      padding-left: 0;
      padding-right: 0;
      .rain-tube{
        display: none;
      }
      .fdp.hourlyrainin,
      .fdp{
        &:before{
          left: 0px;
          width: 100%;
          border-color: var(--dark-gray);
        }
        .suff{
          display: block;
          font-weight: 500;
          font-size: 0.55em;
          padding: 5px 0 3px;
        }
      }
    }
  } 
  .last-rain {
    position: relative;
    top: auto;
    font-size: 1.2em;
    padding: 0px 0 0;
  }
}

.rain-widget {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  .outer-wrap {
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
    text-align: center;

    .rain-wrap {
      display: flex;
      .rain-tube {
        flex-grow: 1;
        display: flex;
        width: 40px;
        // margin: auto;
        overflow: hidden;

        .bg {
          background: url(./../../assets/rain-bg.svg) top left no-repeat;
          background-size: 100%;
          flex-grow: 1;
          // height: 185px;
          position: relative;
          margin-bottom: 20px;

          &:after {
            z-index: 2;
            content: '';
            width: 100%;
            height: 40px;
            background: url(./../../assets/rain-bottom.svg) center center no-repeat;
            background-size: 100%;
            position: absolute;
            left: 0;
            bottom: -20px;
          }

          &:before {
            content: '';
            top: 30px;
            background: url(./../../assets/rain-ticks.svg) 0 0 repeat-y;
            background-size: 30%;
            width: 100%;
            height: 300px;
            z-index: 5;
          }
        }
      }

      &.zero {
        .rain {
          display: none;
        }

        .rain-tube {
          .bg:after {
            background-image: url(./../../assets/rain-bottom-empty.svg);
          }
        }
      }

      .rain {
        height: 50px;
        position: absolute;
        z-index: 4;
        bottom: 0;
        left: 0;
        width: 100%;
        background: url(./../../assets/rain-bar.svg) top left no-repeat;
        background-size: 100%;
      }

      .fdp {
        position: relative;
        display: block;
        margin-bottom: 2px;
        padding-bottom: 2px;

        .suff {
          font-size: 0.8em;
          font-weight: 300;
          display: none;
        }

        &.hourlyrainin .suff {
          font-size: 0.65em;
        }

        &:before {
          content: '';
          position: absolute;
          left: 20px;
          bottom: 0;
          height: 1px;
          width: 56%;
          border-bottom: 1px solid white;
        }
      }
    }
  }

  .last-rain {
    position: absolute;
    text-align: center;
    font-family: Montserrat, serif;

    .days-diff {
      font-weight: bold;
    }
  }
}

.small-card .rain-widget {
  .last-rain{
    position: static;
    text-align: right;
  }
  .outer-wrap{
    flex-basis: 0;
    justify-content: space-around;
    .rain-wrap{
      max-width: 94px;
      flex-grow: 1;
      display: block;

      .fdp:before{
        display: none;
      }
      .rain-tube{
        display: none;
      }
      .wrap{
        padding-top: 30px;
        >.formatted-value{
          font-family: Bahnschrift, serif;
          font-size: 45px;
          font-weight: bold;
          font-stretch: 85%;
          font-style: normal;
          font-variation-settings: normal;
          line-height: normal;
          letter-spacing: -0.56px;
          text-align: center;
          color: var(--dark-gray);

          .suff{
            font-family: Bahnschrift, serif;
            font-size: 20px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            display: block;
            margin-top: -7px;
          }
        }
        >.label{
          font-family: Bahnschrift, serif;
          font-size: 30px;
          font-weight: bold;
          font-stretch: 85%;
          font-style: normal;
          font-variation-settings: normal;
          line-height: normal;
          letter-spacing: -0.38px;
          text-align: center;
          color: var(--dark-gray);
          border-top: solid 1px rgba(46, 49, 146, 0.49);
        }
      }
    }
  }
}