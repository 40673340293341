.dark .weather-screen{
  background-color: #1b1818;

  &.background-forecast{
    background-color: #1b1818;
  }
}

.light{
  .weather-screen{
    .current-update{
      color: var(--black);
    }
  }
}
.weather-screen{
  width: 1280px;
  height: 800px;
  display: flex;
  flex-direction: column;
  background-color: #e8f4fa;
  background-size: cover;

  > div.forecast:first-of-type{
    flex: 1 0 695px;
  }
  >div:last-of-type{
    flex: 0 1 90px;
    background-color: rgba(35, 31, 32, 0.5);
    text-align: center;
    color: #fff;
    font-family: Montserrat, serif;
    font-size: 30px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 58px;
    padding: 16px 35px 16px 16px;

    >svg:first-of-type{
      float: left;
      margin-right: -206px;
    }
    >span{
      margin-left: 60px;
      vertical-align: middle;
    }
    >span + svg{
      margin-left: 27px;
      vertical-align: middle;
    }
    >svg:last-of-type{
      float: right;
      margin-left: -49px;
      margin-top: 6px;
    }
  }
}
.widgets{
    flex: 1;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    /* two rows, both with equal height */
    grid-template-columns: repeat(3, 1fr);
    /* three columns, all with equal width */
    gap: 8px;
    padding: 8px;

    &.layout-1,
    &.layout-3,
    &.layout-2 {
        grid-template-rows: 1.7fr 1fr 1.7fr;
        grid-template-columns: repeat(4, 1fr);
    }

    &.layout-1 {
        > div {
            grid-column: span 1;

            &:nth-child(1) {
                grid-row: span 2;
                grid-column: span 2;
            }

            &:nth-child(4) {
                grid-row: 3 / span 1;
                grid-column: 1 / span 1;
            }

            &:nth-child(5) {
                grid-row: 3 / span 1;
                grid-column: 2 / span 1;
            }

            &:nth-child(6) {
                grid-row: 2 / span 2;
                grid-column: 3 / span 2;
            }
        }
    }

    &.layout-2 {
        > div {
            grid-column: span 1;
            grid-row: span 1;

            &:nth-child(3) {
                grid-row: 1 / span 2;
                grid-column: 3 / span 2;
            }

            &:nth-child(4) {
                grid-row: 3 / span 1;
                grid-column: 1 / span 1;
            }

            &:nth-child(5) {
                grid-row: 3 / span 1;
                grid-column: 2 / span 1;
            }

            &:nth-child(6) {
                grid-row: 3 / span 1;
                grid-column: 3 / span 1;
            }

            &:nth-child(7) {
                grid-row: 3 / span 1;
                grid-column: 4 / span 1;
            }
        }
    }
    &.layout-3 {
      grid-template-rows: 1fr 1fr 1.27fr;

      > div {
          grid-column: span 1;
          grid-row: span 1;

          &:nth-child(1) {
              grid-row: 1 / span 1;
              grid-column: 1 / span 2;
          }

          &:nth-child(2) {
              grid-row: 1 / span 2;
              grid-column: 3 / span 2;
          }

          &:nth-child(3) {
              grid-row: 2 / span 1;
              grid-column: 1 / span 2;
            
          }
      }
    }
  }

  .current-update{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    text-align: center;
    width: 592px;
    height: 125px;    
    word-break: break-word;
    position: absolute;
    top: 292px;
    left: 24px;
    color: white;
    font-family: Montserrat, serif;
    font-size: 35px;
    font-weight: bold;

    span {
      display: inline-block;
      vertical-align: middle;
    }

    .big-number{
      margin-right: .3em;
    }
  }

  .current-update::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
