.column {
  display: flex;
  flex-direction: column;
}

.small-card.lightning .metric-card-footer {
  .footer-column {
    .with-lightning {
      position: relative;

      svg {
        top: -2px;
        height: 19px;
      }
    }
  }
}

.large-card.lightning .metric-card-body {
  .big-value {
    .big-number {
      margin-left: 0.6em;
    }
  }

  .labels {
    font-size: 3.12em;
    line-height: 0.7;

    svg {
      height: 35px;
      width: 27px;
    }
  }
}

.medium-card.lightning .metric-card-body {
  .big-value {
    .big-number {
      margin-left: 0.6em;
    }
  }

  .labels {
    font-size: 2.25em;
    line-height: 0.7;

    svg {
      width: 25px;
      height: 33px;
    }
  }
}

.small-card.lightning .metric-card-body {
  .big-value {
    .big-number {
      margin-left: 0.7em;
    }
  }

  .labels {
    font-size: 1.5em;
    line-height: 0.7;

    svg {
      height: 28px;
      width: 20px;
    }
  }
}


.lightning .metric-card-footer {
  .footer-column {
    .with-lightning {
      position: relative;

      svg {
        position: absolute;
        top: -1px;
        height: 20px;

        path {
          fill: var(--black);
        }
      }
    }
  }
}


.labels {
  @extend .column;
  justify-content: flex-end;
  font-size: 1.5em;
  line-height: 1;
  position: relative;

  svg {
    position: absolute;
    top: -3px;
    left: -3px;

    path {
      fill: var(--black);
    }
  }

  .text-label {
    font-family: 'Bahnschrift', serif;
    font-weight: 600;
    margin-left: 0.2em;
  }
}

  