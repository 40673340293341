@font-face {
    font-family: "Bahnschrift";
    src: url("./assets/Bahnschrift-Font/BAHNSCHRIFT.TTF");
}
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 300;
    src: url(./assets/Ubuntu/Ubuntu-Light.ttf);
}
@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 300;
    src: url(./assets/Ubuntu/Ubuntu-LightItalic.ttf);
}
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: url(./assets/Ubuntu/Ubuntu-Regular.ttf);
}
@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 400;
    src: url(./assets/Ubuntu/Ubuntu-Italic.ttf);
}
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    src: url(./assets/Ubuntu/Ubuntu-Medium.ttf);
}
@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 500;
    src: url(./assets/Ubuntu/Ubuntu-MediumItalic.ttf);
}
@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    src: url(./assets/Ubuntu/Ubuntu-Bold.ttf);
}
@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: 700;
    src: url(./assets/Ubuntu/Ubuntu-BoldItalic.ttf);
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    src: url(./assets/Montserrat/Montserrat-Thin.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 100;
    src: url(./assets/Montserrat/Montserrat-ThinItalic.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    src: url(./assets/Montserrat/Montserrat-ExtraLight.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 200;
    src: url(./assets/Montserrat/Montserrat-ExtraLightItalic.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url(./assets/Montserrat/Montserrat-Light.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 300;
    src: url(./assets/Montserrat/Montserrat-LightItalic.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(./assets/Montserrat/Montserrat-Regular.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: url(./assets/Montserrat/Montserrat-Italic.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url(./assets/Montserrat/Montserrat-Medium.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    src: url(./assets/Montserrat/Montserrat-MediumItalic.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url(./assets/Montserrat/Montserrat-SemiBold.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 600;
    src: url(./assets/Montserrat/Montserrat-SemiBoldItalic.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url(./assets/Montserrat/Montserrat-Bold.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: url(./assets/Montserrat/Montserrat-BoldItalic.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url(./assets/Montserrat/Montserrat-ExtraBold.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    src: url(./assets/Montserrat/Montserrat-ExtraBoldItalic.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url(./assets/Montserrat/Montserrat-Black.ttf);
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 900;
    src: url(./assets/Montserrat/Montserrat-BlackItalic.ttf);
}

:root {
    --background-color: rgba(255, 255, 255, 0.85);
    --border-color: #999;
    --black: #000;
    --dark-gray: #333;
    --footer-border-color: transparent;
}

.dark {
    --background-color: rgba(0, 0, 0, 0.7);
    --border-color: rgba(255, 255, 255, 0.6);
    --black: #fff;
    --dark-gray: #fff;
    --footer-border-color: rgba(255, 255, 255, 0.6);
}

.weather-icon{
    background: url(./assets/weather-icons/sun-light.svg) center center no-repeat;
    background-size: auto 100%;
    &.cloudy{
        background-image: url(./assets/weather-icons/cloud-light.svg);
    }
    &.hail{
        background-image: url(./assets/weather-icons/flurries-light.svg);
    }
    &.partly-cloudy-day{
        background-image: url(./assets/weather-icons/partly-sunny-light.svg);
    }
    &.clear-night{
        background-image: url(./assets/weather-icons/moon-light.svg);
    }
    &.flurries {
        background-image: url(./assets/weather-icons/flurries-light.svg);
    }
    &.fog {
        background-image: url(./assets/weather-icons/fog-light.svg);
    }
    &.lightning {
        background-image: url(./assets/weather-icons/thunder-light.svg);
    }
    &.partly-cloudy-night {
        background-image: url(./assets/weather-icons/partly-cloudy-moon-light.svg);
    }
    &.part-sun {
        background-image: url(./assets/weather-icons/mostly-sunny-light.svg);
    }
    &.rain {
        background-image: url(./assets/weather-icons/rain-light.svg);
    }
    &.snow {
        background-image: url(./assets/weather-icons/snowflake-light.svg);
    }
    &.sleet {
        background-image: url(./assets/weather-icons/sleet-light.svg);
    }
    &.sprinkles {
        background-image: url(./assets/weather-icons/sprinkles-light.svg);
    }
    &.thunderstorm{
        background-image: url(./assets/weather-icons/thunderstorm-light.svg);
    }
    &.wind {
        background-image: url(./assets/weather-icons/wind-light.svg);
    }
}
.dark .weather-icon{
    background: url(./assets/weather-icons/sun-dark.svg) center center no-repeat;
    background-size: auto 100%;
    &.cloudy{
        background-image: url(./assets/weather-icons/cloud-dark.svg);
    }
    &.hail{
        background-image: url(./assets/weather-icons/flurries-dark.svg);
    }
    &.partly-cloudy-day{
        background-image: url(./assets/weather-icons/partly-sunny-dark.svg);
    }
    &.clear-night{
        background-image: url(./assets/weather-icons/moon-dark.svg);
    }
    &.flurries {
        background-image: url(./assets/weather-icons/flurries-dark.svg);
    }
    &.fog {
        background-image: url(./assets/weather-icons/fog-dark.svg);
    }
    &.lightning {
        background-image: url(./assets/weather-icons/thunder-dark.svg);
    }
    &.partly-cloudy-night {
        background-image: url(./assets/weather-icons/partly-cloudy-moon-dark.svg);
    }
    &.part-sun {
        background-image: url(./assets/weather-icons/mostly-sunny-dark.svg);
    }
    &.rain {
        background-image: url(./assets/weather-icons/rain-dark.svg);
    }
    &.snow {
        background-image: url(./assets/weather-icons/snowflake-dark.svg);
    }
    &.sleet {
        background-image: url(./assets/weather-icons/sleet-dark.svg);
    }
    &.sprinkles {
        background-image: url(./assets/weather-icons/sprinkles-dark.svg);
    }
    &.thunderstorm{
        background-image: url(./assets/weather-icons/thunderstorm-dark.svg);
    }
    &.wind {
        background-image: url(./assets/weather-icons/wind-dark.svg);
    }
}