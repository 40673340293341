.device-widget.leafwetbin {
  display: inline-flex;
  flex-direction: column;
  .device-leaf-bin-widget {
    justify-content: center;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .from {
    padding: 0.5rem 1rem;
  }
  .leafwet {
    .fdp-val {        
      font-size: 10vw;
      font-size: clamp(2em, 10vw, 3em);
      color: var(--color-text-widget-big-number);
    }
    .suff{
      font-size: 8vw;
      font-size: clamp(1.5em, 8vw, 1.3em);
      color: var(--color-text-widget-big-number);
    }
  }
  .from {
    .label{
      font-size: 2vw;
      font-size: clamp(0.7em, 2vw, 0.8em);
      margin-bottom: 0.4em;
    }
    .fdp {
      font-size: 2vw;
      font-size: clamp(0.9em, 2vw, 1em);
    }
  }
}

