.metric-card.currentcast {
  >.metric-card-body{
    padding-top: 0;
    padding-bottom: 0;
    display: flex;

    >.hour {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      flex-grow: 1;

      .title {
        font-family: Bahnschrift, serif;
        font-weight: bold;
        font-stretch: 85%;
        color: var(--dark-gray);
        text-align: center;
      }

      .fdp {
        font-family: Bahnschrift,serif;
        font-weight: bold;
        font-stretch: 85%;
        color: var(--dark-gray);
      }
    }
    &.no-data{
      font-size: 21px;
      align-items: center;
      padding-top: 0;
      >div{
        flex-grow: 1;
      }
    }
  }
}

.metric-card.currentcast.small-card {
  >.metric-card-body{
    padding-top: 23px;
    padding-left: 16px;
    padding-right: 16px;
    >.hour {
      .title {
        font-size: 40px;
        letter-spacing: -0.5px;
      }

      .weather-icon{
        height: 27px;
        width: 39px;
        margin-top: 7px;
      }

      .fdp {
        font-size: 60px;
        letter-spacing: -0.75px;
      }
    }
    &.no-data{
      padding-top: 0;
    }
  }
}

.metric-card.currentcast.medium-card {
  >.metric-card-body{
    padding-top: 28px;
    padding-left: 0;
    padding-right: 0;
    >.hour {
      .title {
        font-size: 50px;
        letter-spacing: -0.63px;
      }

      .weather-icon{
        height: 44px;
        width: 64px;
        margin-top: 8px;
      }

      .fdp {
        font-size: 110px;
        letter-spacing: -1.38px;
      }
    }
    &.no-data{
      padding-top: 0;
    }
  }
}

.metric-card.currentcast.large-card {
  >.metric-card-body{
    padding-top: 42px;
    padding-left: 0;
    padding-right: 0;
    >.hour {
      .title {
        font-size: 60px;
        letter-spacing: -0.75px;
      }

      .weather-icon{
        height: 58px;
        width: 84px;
        margin-top: 12px;
      }

      .fdp {
        font-size: 130px;
        letter-spacing: -1.63px;
      }
    }
    &.no-data{
      padding-top: 0;
    }
  }
}