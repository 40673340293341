.listBody {
  display: flex;
  justify-content: space-around;
}

.temprh-icon {
  margin-top: 1.5em;
}

.large-card .metric-card-body.temp-card {
  --body-padding-bottom: 14px;
}

.dark {
  .large-card.temp-card {
    .metric-card-body {
      svg {
        path {
          fill: var(--black);
        }
      }
    }
  }

  .temprh-icon {
    path {
      fill: #FFF;
    }
  }
}

.temprh-tile {
  position: relative;
  .metric-card-footer{
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
}