.webcam.device-widget {
  padding: 0;
  display: flex;
  overflow: hidden;

  .metric-card-header {
    display: none;
  }

  .image-not-available-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    div {
      font-size: 40px;
      font-family: BahnschriftWT600WD100;
      font-weight: 600;
      letter-spacing: -0.5px;
      margin-top: 21px;
    }
  }

  .webcam-widget {
    display: flex;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    flex-grow: 1;
    object-fit: cover;
  }

}

.small-card.webcam.device-widget {
  .image-not-available-container {
    .image-not-available {
      width: 100px;
      height: 91px;
    }

    div {
      margin-top: 21px;
      font-size: 28px;
      letter-spacing: -0.35px;
      font-weight: 600;
    }
  }
}

.medium-card.webcam.device-widget {
  .image-not-available-container {
    .image-not-available {
      width: 134px;
      height: 122px;
    }

    div {
      margin-top: 25px;
      font-size: 34px;
      letter-spacing: -0.4px;
      font-weight: 600;
    }
  }
}