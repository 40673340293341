.metric-card.daycast {
  >.metric-card-body{
    &.no-data{
      font-size: 21px;
      align-items: center;
      padding-top: 0;
      display: flex;
      >div{
        flex-grow: 1;
      }
    }
    >div.cast-row{
      display: flex;
      align-items: center;

      >div:first-of-type{
        font-family: Bahnschrift, serif;
        font-weight: 600;
        color: var(--black);
        margin-right: 8px;
      }
      >div:nth-of-type(2){
        font-family: Bahnschrift, serif;
        font-weight: 600;
        font-stretch: 90%;
        color: var(--black);
        text-align: left;
      }
      >div.with-icon{
        display: flex;
        align-items: center;
        flex-grow: 1;
      }
      >.rain-prob{
        font-family: Bahnschrift,serif;
        font-weight: 300;
        color: var(--black);
        >*{
          vertical-align: middle;
        }
      }
    }
  }
  >.metric-card-footer{
    >.footer-column{
      >div{
        display: inline-block;
      }
    }
  }

  &.small-card{
    >.metric-card-body{
      padding-top: 24px;
      >div.cast-row{
        padding-left: 18px;
        min-height: 72px;
        >div:first-of-type{
          font-size: 30px;
        }
        >div:nth-of-type(2){
          font-size: 60px;
          flex-basis: 106px;
        }
        >div.with-icon{
          > .weather-icon {
            height: 58px;
            flex-grow: 1;
          }
        }
        >.rain-prob{
          font-size: 35px;

          > svg {
            transform: scale(1.6);
            margin-right: -1px;
            margin-left: 18px;
          }
        }
        &.high{
          >div:first-of-type{
            margin-right: 13px;
          }
        }
      }
    }
  }
  &.medium-card{
    >.metric-card-body{
      padding-top: 2px;
      padding-bottom: 2px;
      >div.cast-row{
        margin-bottom: -38px;
        padding-left: 10px;
        min-height: 132px;
        >div:first-of-type{
          font-size: 50px;
        }
        >div:nth-of-type(2){
          font-size: 110px;
          flex-basis: 154px;
        }
        >div.with-icon{
          > .weather-icon {
            height: 72px;
            flex-grow: 1;
          }
        }
        >.rain-prob{
          font-size: 52px;

          > svg {
            transform: scale(1.9);
            margin-right: -1px;
            margin-left: 10px;
          }
        }
        &.high{
          >div:first-of-type{
            margin-right: 16px;
          }
        }
      }
    }
    >.metric-card-footer{
      >.footer-column{
        >div:first-of-type{
          margin-right: 12px;
        }
      }
    }
  }
  &.large-card{
    >.metric-card-body{
      padding-top: 22px;
      padding-bottom: 2px;
      >div.cast-row{
        margin-bottom: -28px;
        padding-left: 38px;
        min-height: 156px;
        >div:first-of-type{
          font-size: 60px;
        }
        >div:nth-of-type(2){
          font-size: 130px;
          flex-basis: 256px;
        }
        >div.with-icon{
          > .weather-icon {
            height: 108px;
            flex-grow: 1;
          }
        }
        >.rain-prob{
          font-size: 80px;

          > svg {
            transform: scale(2.3);
            margin-right: -1px;
            margin-left: 10px;
          }
        }
        &.high{
          >div:first-of-type{
            margin-right: 19px;
          }
        }
      }
    }
    >.metric-card-footer{
      >.footer-column{
        >div:first-of-type{
          margin-right: 12px;
        }
      }
    }
  }
}