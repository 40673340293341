.small-card .big-value{
  --big-number-font-size: 88px;
  --big-number-line-height: 64px;
  --big-number-height: 64px;
  --big-number-bottom: -0.06em;
  --big-number-small-unit-font-size: 24px;
}
.medium-card .big-value{
  --big-number-font-size: 110px;
  --big-number-line-height: 80px;
  --big-number-height: 80px;
  --big-number-bottom: -0.06em;
  --big-number-small-unit-font-size: 36px;
}
.large-card .big-value{
  --big-number-font-size: 130px;
  --big-number-line-height: 94px;
  --big-number-height: 94px;
  --big-number-bottom: -0.06em;
  --big-number-small-unit-font-size: 50px;
}
.big-value{
  display: flex;
  justify-content: center;
  .big-number{
    font-family: Bahnschrift, serif;
    font-weight: bold;
    font-stretch: 85%;
    font-style: normal;
    color: var(--dark-gray);
    font-size: var(--big-number-font-size);
    line-height: var(--big-number-line-height);
    height: var(--big-number-height);

    >span {
      position: relative;
      bottom: var(--big-number-bottom);
    }
    .counter-suffix,
    .counter-prefix{
      visibility: hidden;
    }
    .before,
    .suff{
      font-size: var(--big-number-small-unit-font-size);
      top: 0.22em
    }
  }
}